
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { User } from "@/store/models/user";
import { useStore } from "vuex";
import { userTypes } from "@/constants";
import UpdateProfileModal from "@/components/modals/forms/UpdateProfileModal.vue";

export default defineComponent({
  name: "profile-overview",
  components: { UpdateProfileModal },
  setup() {
    const store = useStore();

    const user = ref<User | null>(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
      if (store.getters.isUserAuthenticated) {
        user.value = store.getters.currentUser;
      }
    });
    watch(
      () => store.getters.currentUser,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          user.value = curVal;
        }
      }
    );
    return { user, userTypes };
  },
});
