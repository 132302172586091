
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import useNotify from "@/core/composables/notify";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { extractElementFormError } from "@/core/helpers/errors";

export default defineComponent({
  name: "update-profile-modal",
  components: {},
  setup() {
    const { toastErrorRaw, toastError } = useNotify();
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      first_name: store.getters.currentUser?.first_name || "",
      last_name: store.getters.currentUser?.last_name || "",
      street_address: store.getters.currentUser?.street_address || "",
      city: store.getters.currentUser?.city || "",
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last name is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          try {
            loading.value = true;
            await store.dispatch(Actions.UPDATE_USER, formData.value);
            hideModal(addUserModalRef.value);
            formRef.value?.resetFields();
          } catch (error) {
            toastErrorRaw(error);
          } finally {
            loading.value = false;
          }
        } else {
          toastError({
            text: extractElementFormError(fields),
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addUserModalRef,
    };
  },
});
